.PopUp
  max-width: 500px
  width: 100%
  background-color: #fff
  padding: 24px
  position: absolute
  top: 96px
  left: 50%
  transform: translateX(-50%)
  z-index: 10
  border-radius: 16px
  .PopUp-Header
    font-size: 24px
    color: #000
    font-weight: 700
    display: flex
    justify-content: space-between
    align-items: center
    svg
      color: var(--darkGray)
      cursor: pointer
      &:hover
        color: #000
  .ControllButtons
    border-bottom-left-radius: 8px
    border-bottom-right-radius: 8px
  .ControllButtons-Container
    justify-content: center
  .SubmitFormButtons
    padding-bottom: 0
@media screen and ( max-width: 576px )
  .PopUp
    width: calc(100% - 32px)
