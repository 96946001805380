//All fields like input, textarea
.Field-Container
  width: 100%
.Field
  position: relative
  display: flex
  align-items: center
  border: 1px solid var(--lightGray)
  min-height: 47px
  cursor: pointer
  background-color: #fff

  input, textarea
    width: 100%
    font-size: 14px
    display: block
    padding: 12px 16px
    cursor: pointer
    border: 0

  textarea
    resize: none
    height: 70px
    padding-top: 14px

  label
    text-align: left
    color: var(--black)
    font-size: 14px
    position: absolute
    pointer-events: none
    left: 16px
    top: 12px
    transition: 0.2s ease all

  .Field-Bar
    display: none
    position: absolute
    bottom: -1px
    left: 0
    width: 100%
    &:before,
    &:after
      content: ""
      height: 2px
      width: 0
      bottom: 0
      position: absolute
      background: var(--accent)
      transition: 0.2s ease all

    &:before
      left: 50%

    &:after
      right: 50%

  input:focus, textarea:focus, .Select_theme_focused
    ~ .Field-Bar:before,
    ~ .Field-Bar:after
      width: 50%

  .Field-Icon
    margin-right: 8px
    width: 12px
    height: 12px
    font-size: 14px
    color: var(--darkGray)
    text-align: center
    appearance: none
    outline: none
    display: flex
    align-items: center
    justify-content: center

  .Field-FuncionalIcon
    border-radius: 100%
    font-size: 8px
    cursor: pointer

  input[type='file']
    position: absolute
    width: 100% !important
    height: 100% !important
    z-index: 1 !important
    opacity: 0

.Field-Icon_position_left
  .Field-Icon
    order: 0
    margin-left: 16px
    margin-right: 0
  input
    order: 1
  label
    left: 28px
.Field-Error
  display: none
  position: absolute
  height: 20px
  background-color: var(--notyColor)
  bottom: -21px
  padding: 0px 4px
  color: #ffffff
  z-index: 2
  white-space: nowrap

.Field_status_invalid
  .Field-Bar
    &:before,
    &:after
      background: var(--notyColor)

.Field_with_value
  label
    top: -24px
    left: 0
    font-weight: 600
    text-transform: uppercase
    font-size: 9px

.Field-Textarea
  label
    width: 100%

//Standalone links in form
.Field-Link
  height: 16px
  line-height: 12px
  color: var(--activeGray) !important
  text-decoration: underline
  &:hover
    text-decoration: none

.Checkbox
  position: relative
  // margin-bottom: 24px
  display: flex
  align-items: center
  justify-content: space-between
  .Field-Icon
    margin-top: 4px
    margin-right: 8px
    color: var(--darkGray)

.Field-Checkbox
  z-index: 0
  position: relative
  display: inline-block
  color: #000
  font-size: 12px
  height: 16px

  input
    z-index: -1
    position: absolute
    left: -4px
    top: -3px
    display: block
    margin: 0
    border-radius: 50%
    width: 24px
    height: 24px
    background-color: var(--activeGray)
    box-shadow: none
    opacity: 0
    transform: scale(1)
    pointer-events: none
    transition: opacity 0.3s, transform 0.2s

  span
    display: inline-block
    width: 100%
    cursor: pointer

  span::before
    content: ""
    display: inline-block
    box-sizing: border-box
    margin: 1px 8px 0px 0px
    border: solid 1px var(--lightGray)
    border-radius: 4px
    width: 16px
    height: 16px
    vertical-align: top
    transition: border-color 0.2s, background-color 0.2s

  span::after
    content: ""
    display: block
    position: absolute
    top: 2px
    left: 0px
    width: 10px
    height: 5px
    border: solid 2px transparent
    border-right: none
    border-top: none
    transform: translate(3px, 4px) rotate(-45deg)

  input:checked,
  input:indeterminate
    background-color: var(--darkGray)

  input:checked + span::before,
  input:indeterminate + span::before
    border-color: var(--darkGray)
    background-color: #fff

  input:checked + span::after,
  input:indeterminate + span::after
    border-color: var(--darkGray)

  input:indeterminate + span::after
    border-left: none
    transform: translate(4px, 3px)

.Field-Checkbox:hover input
  opacity: 0.04

.Field-Checkbox input:focus
  opacity: 0.12

.Field-Checkbox:hover input:focus
  opacity: 0.16

.Field-Checkbox input:active
  opacity: 1
  transform: scale(0)
  transition: transform 0s, opacity 0s

.Field-Checkbox input:active + span::before
  border-color: var(--activeGray)

.Field-Checkbox input:checked:active + span::before
  border-color: transparent
  background-color: var(--activeGray)

.Field-Checkbox input:disabled
  opacity: 0

.Field-Checkbox input:disabled + span
  cursor: initial

.Field-Checkbox input:disabled + span::before
  border-color: var(--lightGray)

.Field-Checkbox input:checked:disabled + span::before,
.Field-Checkbox input:indeterminate:disabled + span::before
  border-color: transparent
  background-color: currentColor

.Field-PhotoBlock
  width: 80px
  height: 80px
  background-color: #EDEFF1
  display: flex
  justify-content: center
  align-items: center
  color: #fff
  position: relative
  cursor: pointer
  &:not(:last-child)
    margin-right: 16px
  img
    width: 80px
    height: 80px
    object-fit: cover
    object-position: top center

  input
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    opacity: 0

.Radio
  width: auto
  display: inline-flex
  align-items: center
  position: relative
  cursor: pointer

.Field-Radio
  position: absolute
  z-index: 3
  opacity: 0
  cursor: pointer
  height: 0
  width: 0

.Radio-Checkmark
  height: 12px
  width: 12px
  border: 1px var(--lightGray) solid
  border-radius: 100%
  font-size: 8px
  display: flex
  justify-content: center
  align-items: center
  flex-shrink: 0
  background-color: #fff
  svg
    opacity: 0

.Radio:hover input ~ .Radio-Checkmark
  color: var(--activeGray)
  svg
    opacity: 1

.Radio input:checked ~ .Radio-Checkmark
  color: var(--activeGray)
  svg
    opacity: 1

.Fieldset-Button
  width: 16px !important
  height: 16px !important
  margin-top: 13px

.Field-ReadOnly
  svg
    transform: rotate(0deg) !important
  .Field-Bar
    width: 0 !important
  .Field-Icon
    font-size: 12px
