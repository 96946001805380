.Settings-Content
  display: grid
  grid-template-columns: 1fr
  grid-gap: 32px
  padding: 24px 0px
  .Title
    font-size: 18px
    font-weight: 500
  .MainInfo
    display: grid
    grid-template-columns: 1fr
    grid-gap: 32px
    .Fieldset
      display: grid
      grid-template-columns: 1fr 24px
      grid-gap: 24px
      .Button
        margin-top: 32px
    .Field-Container
      grid-column: 1/-1

  .Buttons
    display: flex
    .Button
      &:not(:last-child)
        margin-right: 16px
