.Phone
  position: relative
  display: grid
  grid-gap: 16px
  grid-template-columns: min-content 1fr
  align-items: center

.CountrySelect
  padding: 0px 12px
  display: grid
  grid-template-columns: 16px min-content 14px
  grid-gap: 8px
  align-items: center
  .Select
    padding: 0 !important
    width: 20px
  label
    left: 0
  .Field-SelectAngle-Icon
    margin-right: 0
    width: 14px
    height: 14px
    font-size: 14px
    color: var(--darkGray)
    display: flex
    justify-content: center
    align-items: center
.CountryFlag
  width: 16px !important
  height: 12px
  flex-shrink: 0

.PhoneInput
  .PhoneInputCountry
    display: none
