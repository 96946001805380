.DeletePopUp-WarningMessage
  display: flex
  padding: 8px
  background-color: var(--failColor)
  margin-top: 12px
  color: var(--notyColor)
  font-size: 12px
  align-items: center
  p
    margin-bottom: 0
    .Bolder
      font-weight: 700

.DeletePopUp-WarningIcon
  font-size: 18px
  color: var(--notyColor)
  margin-right: 12px

.DeletePopUp-FieldLabel
  margin-bottom: 0
  margin: 16px 0px
  .FieldLabel-Identifier
    font-weight: 600
