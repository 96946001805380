.Auth
  width: 100%
  background-color: #fff
  padding-top: 128px
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
.AuthForm
  margin-top: 32px
  padding: 48px
  background-color: #fff
  border: 1px var(--darkGray) solid
  width: 100%
  max-width: 500px
  display: grid
  grid-template-columns: 1fr
  grid-gap: 32px
  h4
    font-size: 24px
    text-align: center
  .Field
    border: 1px #000 solid
.AuthForm-Buttons
  display: flex
  justify-content: center
