.SpecializationModule
.SpecializationModule-Content
  display: grid
  grid-template-columns: 1fr
  grid-gap: 12px
  .CategoryTitle, .ServiceTitle
    display: flex
    align-items: center
    cursor: pointer
  .Chooser
    width: 24px
    height: 24px
    display: flex
    justify-content: center
    align-items: center
    font-size: 14px
    margin-right: 12px
    border: 1px var(--lightGray) solid
    border-radius: 4px
    color: #fff
  .Chooser_theme_active
    color: var(--accent)
  .ServicesContent
    display: grid
    grid-template-columns: 1fr
    grid-gap: 12px
    margin-left: 36px
    margin-top: 12px
