.PagesNav
  width: 200px
  padding: 8px
  border-radius: 8px
  border: 1px var(--lightGray) solid
  text-align: left
  display: grid
  grid-template-columns: 1fr
  grid-gap: 4px
  align-content: start
  .PageNavElement
    padding: 8px
    color: var(--black)
    cursor: pointer
    border-radius: 4px
    &:hover
      background-color: var(--darkGray)
      color: var(--lightGray)
  .PageNavElement-Active
    background-color: var(--accent) !important
    color: #fff
