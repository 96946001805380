.Button
  display: inline-flex
  height: 40px
  font-size: 13px
  font-weight: 500
  padding: 0px 16px
  text-align: center
  justify-content: center
  align-items: center
  cursor: pointer
  appearance: none !important
  outline: none !important
  color: #fff
  border: 0px
  transition: all 0.3s linear
  transition-property: background, color, border
  position: relative
  overflow: hidden
  border-radius: 4px
.Button-Content
  display: inline-flex
  text-align: center
  justify-content: center
  align-items: center
  position: relative
  z-index: 2

.Button_theme_circle
  padding: 0
  width: 24px
  height: 24px
  font-size: 18px
  border-radius: 100%
  text-align: center
  display: flex
  justify-content: center
  align-items: center
  flex-shrink: 0
  border: 0px !important
  .Button-IconContainer
    font-size: 11px
    margin-right: 0 !important

.Button_theme_transparent, .Button_theme_bounded
  background: none !important

.Button_theme_transparent
  border: 0 !important
.Button_theme_transparent-hug
  background: none !important
  height: auto
  padding: 0
  color: var(--black)
  &:hover
    text-decoration: underline

.Button_fill_accent
  background: var(--accent)
  color: #fff !important
  border: 1px var(--accent) solid
  &:hover
    color: var(--black) !important
    background: var(--lightGray)
  &:active
    color: #fff !important
    background: var(--black)

.Button_fill_noty
  background: var(--notyColor)
  color: #fff !important
  &:hover
    color: var(--black) !important
    background: var(--lightGray)
  &:active
    color: #fff !important
    background: var(--black)

.Button_fill_accent2
  background: var(--accent2)
  color: #fff !important
  &:hover
    background: var(--darkGray)
  &:active
    background: var(--accent)

.Button_fill_white
  background: #ffffff
  &:hover
    background: var(--pushedButton)
    color: #fff
    &::after
      top: 0
      background-color: #000
  &:active
    color: #fff !important
    background: #000

.Button_fill_normal
  background: #fff
  color: var(--black)
  border: 1px var(--darkGray) solid
  &:hover
    background: var(--accent)
    color: #fff
    &::after
      top: 0
      background-color: var(--activeGray)
  &:active
    &:after
      color: #fff !important
      background: var(--lightGray)

.Button_fill_gray
  background: var(--darkGray)
  color: #fff
  &:hover
    background: var(--pushedButton)
    color: #fff
    &::after
      top: 0
      background-color: var(--activeGray)
  &:active
    &:after
      color: #fff !important
      background: var(--lightGray)

.Button_fill_black
  background: var(--black)
  color: #fff
  &:hover
    background: var(--accent2)
    color: #fff
  &:active
    background: var(--lightGray)
    color: var(--black)

// .Button_fill_black
//   background: var(--blackColor)
//   color: #fff !important
//   box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.07)
//   &:hover
//     background: var(--blackLightColor)
//     color: #fff
//   &:active
//     color: #fff !important
//     background: var(--blackColor)

// .Button_fill_accent,
//   background: var(--accentColor)
//   color: #fff !important
//   &:hover
//     background: var(--accentDark)
//     color: #fff
//   &:active
//     color: #fff
//     background: var(--blackColor)
// .Button_fill_accentLight
//   background: var(--accentLightColor)
//   color: #fff !important
//   &:hover
//     background: var(--accentColor)
//     color: #fff
//   &:active
//     color: #fff
//     background: var(--blackColor)

// .Button_fill_noty
//   background: var(--notyColor)
//   color: #fff !important
//   &:hover
//     color: #fff
//     background: #FF7199
//   &:active
//     color: #fff
//     background-color: var(--blackColor)

// .Button_color_accent
//   color: var(--accentColor)
.Button_color_orange
  color: var(--accent)
  &:hover
    color: var(--darkGray)
  &:active
    color: #000
.Button_color_gray
  color: var(--darkGray)
  &:hover
    color: #000
.Button_color_white
  color: #fff
.Button_color_blue
  color: var(--accent)
// .Button_color_noty
//   color: var(--notyColor)
// .Button_color_black
//   color: var(--blackColor)

// .Button_border_accent
//   border: 1px var(--accentColor) solid
// .Button_border_noty
//   border: 1px var(--notyColor) solid
.Button_border_white
  border: 2px #fff solid
  &:hover
    border: 2px var(--lightGray) solid
  &:active
    border: 2px var(--darkGray) solid
.Button_border_gray
  border: 2px var(--lightGray) solid
  &:hover
    border: 2px var(--lightGray) solid
  &:active
    border: 2px var(--darkGray) solid

.Button_border_blue
  border: 2px var(--accent) solid
  &:hover
    border: 2px var(--lightGray) solid
  &:active
    border: 2px var(--darkGray) solid

// .Button_color_white
//   color: var(--accentColor)
//   &:hover
//     box-shadow: none
//     color: #fff
//     background: var(--accentDarken)
//   &:active
//     box-shadow: none
//     color: #fff
//     background-color: var(--blackColor)

.Button-IconContainer
  width: 16px
  height: 16px
  display: flex
  justify-content: center
  align-items: center
  font-size: 12px

.Button_icon_position_left
  .Button-IconContainer
    order: 0
    margin-right: 8px
  .Button-Content
    order: 1
.Button_icon_position_right
  .Button-IconContainer
    order: 1
    margin-left: 8px
  .Button-Content
    order: 0

.Button_size_28
  height: 28px
  padding: 0px 12px

.Button_size_32
  height: 32px
  padding: 0px 12px

.Button_size_48
  height: 48px
  padding: 0px 16px
  font-weight: 600
