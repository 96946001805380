.ToursCatalog
  display: grid
  grid-template-columns: 1fr
  grid-gap: 24px
.ToursCatalog-SortableGrid
  display: grid
  grid-template-columns: 1fr
  grid-gap: 24px
.ToursCatalog-UpdateSortButton
  height: 28px
  margin-right: 16px
.EmptyText
  text-align: center
  color: var(--darkGray)
.TourTile
  display: grid
  grid-template-columns: 120px 1fr max-content
  grid-gap: 16px
  cursor: pointer
  .Avatar
    width: 120px
    height: 80px
    span
      width: 100%
      height: 100%
    img
      width: 100%
      height: 100%
      object-fit: contain
      object-position: center center
      border-radius: 8px
  .Content
    display: grid
    grid-template-columns: 1fr
    grid-gap: 8px
    .Title
      font-size: 18px
    .Status
      padding: 4px 8px
      border-radius: 8px
      font-size: 12px
    .Published
      background-color: var(--successColor)
      color: var(--accent)
    .NotPublished
      background-color: var(--failColor)
  .Options
    display: flex
    .OptionButton
      width: 32px
      height: 32px
      display: flex
      justify-content: center
      align-items: center
      border-radius: 8px
      cursor: pointer
      &:not(:last-child)
        margin-right: 16px
    .Accent
      background-color: var(--accent)
      color: #fff
    .Red
      background-color: var(--accent2)
      color: #fff
    .Gray
      background-color: var(--lightGray)
      color: var(--black)
