.MapMarker
  width: 48px
  height: 48px
  background-color: #000
  color: #fff
  display: flex
  justify-content: center
  align-items: center
  font-size: 16px
  transform: translateY(-100%)
  &::after
    content: ''
    width: 4px
    height: 4px
    position: absolute
    bottom: -6px
    left: -6px
    background-color: #000
