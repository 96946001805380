.DoctorsCatalog
  display: grid
  grid-template-columns: 1fr
  grid-gap: 24px
.DoctorsCatalog-SortableGrid
  display: grid
  grid-template-columns: 1fr
  grid-gap: 24px
.DoctorsList-UpdateSortButton
  height: 28px
  margin-right: 16px
.EmptyText
  text-align: center
  color: var(--darkGray)
.DoctorTile
  display: grid
  grid-template-columns: 80px 1fr max-content
  grid-gap: 16px
  cursor: pointer
  align-items: start
  border-bottom: 1px var(--lightGray) solid
  padding: 24px 12px
  &:hover
    background-color: var(--lightGray)
  .Avatar
    width: 80px
    height: 80px
    border-radius: 8px
    span
      width: 100%
      height: 100%
    img
      width: 100%
      height: 100%
      object-fit: cover
      object-position: top center
      border-radius: 8px
    .DoctorAvatar
      font-size: 32px
      background-color: var(--background)
      width: 100%
      height: 100%
      display: flex
      justify-content: center
      align-items: center
      color: var(--accent)
  .Content
    display: grid
    grid-template-columns: 1fr
    grid-gap: 8px
    .Title
      font-size: 18px
    .Status
      padding: 4px 8px
      border-radius: 8px
      font-size: 12px
    .Published
      background-color: var(--successColor)
      color: var(--accent)
    .NotPublished
      background-color: var(--failColor)
  .Options
    display: flex
    .OptionButton
      width: 32px
      height: 32px
      display: flex
      justify-content: center
      align-items: center
      border-radius: 8px
      cursor: pointer
      &:not(:last-child)
        margin-right: 16px
    .Accent
      background-color: var(--accent)
      color: #fff
    .Red
      background-color: var(--accent2)
      color: #fff
    .Gray
      background-color: var(--lightGray)
      color: var(--black)
