.CategoryPriceTile
  .Title
    font-size: 18px
    margin-bottom: 24px
    font-weight: 500
    display: flex
    align-items: center
    .CategoryPriceTile-Buttons
      margin-left: 16px
      display: flex
      align-items: center
      .Button
        &:first-child
          margin-right: 16px
    .Button-IconContainer
      margin-right: 0
    .AddCategoryButton
      margin-left: auto
  .TableActions-FrontActions
    display: flex
    align-items: center
    .TableActions-IconContainer
      cursor: pointer
      width: 28px
      height: 28px
      border-radius: 100%
      display: flex
      justify-content: center
      align-items: center
      border: 1px var(--darkGray) solid
      &:hover
        background-color: var(--darkGray)
        .Icon
          color: #fff
      .Icon
        color: #000
      &:first-child
        margin-right: 12px
